@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .container {
    @apply bg-white dark:bg-zinc-900 border border-zinc-200 dark:border-zinc-600 rounded-lg;
  }
  .p-container {
    @apply px-4 py-6 sm:p-6;
  }
  .icon-container {
    @apply flex items-center justify-center;
  }
  .footer-container {
    @apply px-4 py-3 sm:px-6 border-t dark:border-none bg-zinc-50 dark:bg-zinc-900 text-right;
  }
  .divider {
    @apply w-full border-t border-zinc-200 dark:border-zinc-600;
  }
  .menu-item {
    @apply flex flex-row items-center px-4 py-2 text-sm text-zinc-700 dark:text-zinc-400 hover:bg-zinc-100 dark:hover:bg-zinc-900 hover:text-zinc-900 dark:hover:text-zinc-200 cursor-pointer;
  }
  .bg-primary {
    @apply bg-white dark:bg-black;
  }
  .bg-secondary {
    @apply bg-zinc-100 dark:bg-zinc-900;
  }
  .bg-overlay {
    @apply bg-opacity-75 bg-zinc-500 dark:bg-zinc-700 dark:bg-opacity-90;
  }
  .border-primary {
    @apply border border-zinc-200 dark:border-zinc-600;
  }
  .border-primary-t {
    @apply border-t border-zinc-200 dark:border-zinc-600;
  }
  .border-primary-r {
    @apply border-r border-zinc-200 dark:border-zinc-600;
  }
  .border-primary-b {
    @apply border-b border-zinc-200 dark:border-zinc-600;
  }
  .border-primary-l {
    @apply border-l border-zinc-200 dark:border-zinc-600;
  }
  .divide-primary-y {
    @apply divide-y divide-zinc-200 dark:divide-zinc-600;
  }
  .ring-primary {
    @apply ring-1 ring-black dark:ring-zinc-600 ring-opacity-10;
  }
  .text-input-primary {
    @apply text-sm text-black dark:text-white dark:bg-zinc-900 placeholder-zinc-300 dark:placeholder-zinc-700 disabled:bg-zinc-50 disabled:dark:bg-zinc-800 disabled:text-zinc-600 disabled:dark:text-zinc-400;
  }
  .text-primary {
    @apply text-base text-zinc-900 dark:text-white;
  }
  .text-secondary {
    @apply text-xs text-zinc-700 dark:text-zinc-300;
  }
  .text-label {
    @apply text-xs text-zinc-400 dark:text-zinc-500;
  }
  .text-underline {
    @apply hover:underline hover:text-purple-700 dark:hover:text-purple-300;
  }
  .text-color-purple {
    @apply text-purple-600 dark:text-purple-500 hover:text-purple-700 dark:hover:text-purple-400;
  }
  .icon-color-purple {
    @apply text-purple-500 dark:text-purple-600;
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

html {
  @apply h-full bg-white;
}

body {
  @apply h-full;
}

body > * {
  @apply h-full overflow-hidden;
}

.gradient {
  background: linear-gradient(180deg, rgba(201, 125, 215, 0.9) 8.19%, rgba(101, 61, 181, 0.9) 100%);
}
